<template>
    <main class="form-signin" >
        <form class="p-3  border rounded ">
            <div style="display: flex;align-items: center;flex-direction: column;">
                <img class="mb-4" src="../assets/vpsLogo.png" alt="" >
                <h1 class="h3 mb-2 fw-normal">Facturación</h1>
            </div>
            <div class="mb-2 row">
                <label for="staticNit" class="col-sm-4 col-form-label">NIT o email</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="staticNit" v-model="nit">
                </div>
            </div>
            <div class="mb-2 row">
                <label for="staticPassword" class="col-sm-4 col-form-label">Contraseña</label>
                <div class="col-sm-8">
                    <input type="password" class="form-control" id="staticPassword" v-model="pasword">
                </div>
            </div>

            <button class="w-100 btn btn-lg btn-primary" @click="autenticar()">Iniciar sesión</button>
            <br>
            <br>
            <div v-if="error">
                <div class="alert alert-danger">
                    {{error}}
                </div>
            </div>
        </form>
    </main>
</template>

<script>
    import FacturacionService from '@/services/FacturacionService';
    import EmpresaService from "@/services/EmpresaService";
    import axios from "@/api/axios"; 

    export default {
        name: 'LoginVue',
        data() {
            return {
                ping: null,
                nit: null,
                pasword: null,
                error: null,
                cargando: false
            }
        },
        emit: ["logueado"],
        mounted(){
            //Comprueba el estado de la conexion
            FacturacionService.ping().then(r => this.ping = r.data);
            setTimeout(function(){
                var mensaje = sessionStorage.getItem("mensaje");
                if(mensaje != null){
                    console.log(mensaje);
                    window.alert(mensaje); 
                    sessionStorage.removeItem("mensaje")
                }  
            }, 100);
            
        },
        methods: {
            /*
            * Autentica la empresa
            */
            autenticar() {
                this.error = null;
                //Valida los campos
                if(!this.nit )
                    return this.error = "El nit es requerido";
                if(!this.pasword)
                    return this.error = "La contraseña es requerida";

                //Autentica la empresa
                EmpresaService.autenticar(this.nit, this.pasword).then(r=>{
                    var data = r.data;
                    //Usuario de la empresa
                    var empresa = JSON.stringify(data.usuario);
                    sessionStorage.setItem("empresa", empresa);
                    //Optiene el token
                    var token = data.access_token
                    //Guarda el token en la session
                    sessionStorage.setItem("Token", token);

                    //Genera el header del aplicativo
                    axios.defaults.headers.common['Authorization'] =  'bearer ' + token;
                    this.$emit('logueado', true);

                    window.location = "#/inicio";
                }).catch(e=>{
                    this.mostrarError(e);
                });
            },

            /*
            * Muestra el error o las advertencias al autenticarse
            */
            mostrarError(e){
                console.log(e.response ? e.response : e);
                if(e.response?.data)
                    this.error = e.response.data.detail;
                else
                    this.error = e;
            }
        }
    }
</script>

<style>
    .form {
        display: flex;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #f5f5f5;
    }
    .bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    @media (min-width: 768px) {
        .bd-placeholder-img-lg {
            font-size: 3.5rem;
        }
    }

    .form-signin {
        width: 100%;
        max-width: 360px;
        padding: 15px;
        margin: auto;
        align-items: center;
    }

    .form-signin .checkbox {
        font-weight: 400;
    }

    .form-signin .form-floating:focus-within {
        z-index: 2;
    }

    .form-signin input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .form-signin input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
</style>