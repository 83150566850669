<template>
  <LoginVue v-if="logueado == false" style="padding-top: 15%;" @logueado="autenticado"></LoginVue>
  <div v-else >
    <header class="navbar navbar-dark sticky-top bg-morado flex-md-nowrap p-0 shadow">
      <div class="navbar-brand col-md-3 col-lg-2 me-0 px-3">
        <img src="@\assets\vpsLogo.png" class="rounded" width="98px" height="28px"/>
        <span class="text-white">Facturación</span>
      </div>
      <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation" >
        <i class="fa-solid fa-bars"></i>
      </button>
      &nbsp;&nbsp;&nbsp;
      <div class="dropdown text-end text-nowrap d-none d-sm-block flex-wrap-reverse " style="right: 10px;">
        <button class="btn d-block text-secondary text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fa-regular fa-user"></i>&nbsp;{{empresa["nombre"]}}<span v-if="empresa['test']"> TEST</span>
        </button>
        <ul class="dropdown-menu text-small" aria-labelledby="dropdownUser1">
          <li><a class="dropdown-item" @click="empresaModal = true">Perfil</a></li>
          <li><a class="dropdown-item btn" @click="actualizar=true" >Cambiar contraseña</a></li>
          <li><hr class="dropdown-divider"></li>
          <li><a class="dropdown-item" href="#/" @click="salir()" ><i class="fa-solid fa fa-sign-out fa-right-from-bracket" aria-hidden="true"></i>&nbsp;Salir</a></li>
        </ul>
      </div>
      
    </header>
    

    <div class="container-fluid">
      <div class="row">
          <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse" >
          <div class="position-sticky pt-2" v-if="empresa">
          <br>
          <br>
              <ul class="nav flex-column">
                <li class="nav-item">
                    <a class="nav-link" aria-current="page" href="#inicio">
                      <i class="fa-solid fa-house"></i>&nbsp;
                      Inicio
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#facturas/factura" >
                      <i class="fa-solid fa-file"></i>
                      Facturas
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#facturas/noAprobadas">
                      <i class="fa-solid fa-file-circle-xmark"></i>
                      No aprobadas
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#facturas/sinPDF">
                      <i class="fa-solid fa-file-circle-exclamation"></i>
                      Sin PDF
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#validacion">
                      <i class="fa-solid fa-arrow-down-1-9"></i>&nbsp;Verificar consecutivos
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#soportes">
                      <i class="fa-solid fa-paste"></i>&nbsp;Soportes
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#proveedores">
                      <i class="fa-solid fa-users"></i>&nbsp;Proveedores
                    </a>
                </li>
                <li class="nav-item" v-if="empresa['superadmin']">
                    <a class="nav-link" href="#resumen">
                      <i class="fa-solid fa-clipboard-list"></i>&nbsp;Resumen
                    </a>
                </li>
                <li class="nav-item" v-if="empresa['superadmin']">
                    <a class="nav-link" href="#empresas">
                      <i class="fa-solid fa-building-user"></i>&nbsp;Empresas
                    </a>
                </li>
                <li class="border-top my-3"></li>
                <li class="nav-item">
                  <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#accountCollapse" aria-expanded="false">
                    <i class="fa-solid fa-regular fa-user"></i>&nbsp;Empresa
                  </button>
                  <div class="collapse" id="accountCollapse">
                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li>
                        <a @click="empresaModal = true" class="link-dark rounded btn">Perfil</a>
                        <!-- a> href="#empresa" class="link-dark rounded btn" >Perfil</a -->
                      </li>
                      <li><a @click="actualizar=true" class="link-dark rounded btn" style="text-decoration: none;">Cambiar contraseña</a>
                      </li>
                    </ul>
                  </div>
        
                </li>
                <li class="nav-item">
                  <a class="nav-link px-3"  @click="salir()" href="#/">
                    <i class="fa fa-sign-out fa-right-from-bracket" aria-hidden="true"></i>&nbsp;Salir
                  </a>&nbsp;&nbsp;
                </li>
              </ul>
            </div>
          </nav>
          <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">

            <ActualizarPasword v-if="actualizar" @cerrar="actualizar = false" />
            <ModalEmpresa v-if="empresaModal" :idEmpresa="empresa.id_empresa" :empresaPersonal="true" @ok="empresaModal=false;" @cerrar="empresaModal=false;">
            </ModalEmpresa>
            <router-view/>
          </main>
      </div>
    </div>
  </div>
</template>
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1">
<script>
//import {Tooltip} from "bootstrap"

import LoginVue from '@/components/Login.vue';
import ActualizarPasword from '@/components/Modales/ActualizarPasword.vue'
import ModalEmpresa from "@/components/Modales/Empresa.vue"

import EmpresaService from "@/services/EmpresaService";

export default {
  name: 'App',
  components:{
    LoginVue,
    ActualizarPasword,
    ModalEmpresa
  },
  data() {
    return {
      logueado: false,
      empresa: null,
      actualizar: false,
      paswords: {actual: null, nueva1: null, nueva2: null},
      errorPasword: null,
      empresaModal: false
    }
  },
  watch: {
    $route() {
      var login = EmpresaService.getLogin();
      if(!login)
        this.salir();
    }
  },
  mounted(){
    /*
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });*/

    var logeado = EmpresaService.getLogin();
    
    if(logeado)
      this.autenticado(logeado);

  },
  methods: {

    autenticado(logeado){
      if(logeado){
        this.empresa = EmpresaService.getEmpresaUser();
        
        this.logueado = logeado;
      }
    },

    salir(){
      this.logueado = EmpresaService.salir();
      //if(this.empresa != null)
      //  window.location.reload();
    }
  }
  
}
</script>
<style>
#app{
  position: relative;
  min-height: 100%;
}


body {
  font-size: .875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #2470dc;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.bg-morado {
  background-color: #2E257F;
}


.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
  display: flex;
    align-items: center;
    flex-direction: column;
}

.navbar .navbar-toggler {
  top: .25rem;
  right: 1rem;
}

.bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
      }

      @media (min-width: 768px) {
        .bd-placeholder-img-lg {
          font-size: 3.5rem;
        }
      }

.centarItems{
  display: flex;
  justify-content: center;
}
</style>
