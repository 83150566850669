<template>
    <ModalVue @cancel="$emit('cerrar')" :modoConfimacion="true">
        <template v-slot:header>
            <h3>Claves Técnicas</h3>
        </template>
        <template v-slot:body>
            <div v-if="cargando" class="centarItems">
                <br>
                <i class="fas fa-spinner fa-spin  fa-3x"></i>
            </div>
            <div v-if="cargando == false && error == null " class="table-responsive">
                <label>Resultados: <span class="badge bg-secondary">{{clavesTecnicas.length}}</span></label>
                <br>
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Empresa</th>
                            <th>Número de resolución</th>
                            <th>Prefijo</th>
                            <th>Desde</th>
                            <th>Hasta</th>
                            <th>Fecha desde</th>
                            <th>Fecha hasta</th>
                            <th>Clave técnica</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="c in clavesTecnicas" v-bind:key="c.resolutionNumber">
                            <td>{{c.Empresa}}</td>
                            <td>{{c.resolutionNumber}}</td>
                            <td>{{c.prefix}}</td>
                            <td>{{c.minNumber}}</td>
                            <td>{{c.maxNumber}}</td>
                            <td>{{c.startDate}}</td>
                            <td>{{c.endDate}}</td>
                            <td>{{c.technicalKey}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="cargando == false && error != null" class="centarItems">
                <div class="alert alert-danger">
                    {{error}}
                </div>
            </div>
        </template>
    </ModalVue>
</template>
<script>
import EmpresaService from "@/services/EmpresaService";
import ModalVue from '@/components/Modales/Modal.vue'

import _ from 'underscore';

    export default {
        name: 'ClavesTecnicas',
        props:{
            idEmpresa: Number
        },
        components:{
            ModalVue
        },
        data() {
            return {
                //Contiene la empresa del usuario
                empresa: null,
                //Contiene las contraseñas
                clavesTecnicas: [],

                cargando: true,
                error: null,
            }
        },
        emits: ["cerrar"],
        mounted(){

            //Consulta los datos
            EmpresaService.getClaveTecnica(this.idEmpresa).then(r=>{
                var data = r.data;
                //Ordena los datos a mostrar
                data = _.uniq(data);
                this.clavesTecnicas = _.sortBy(data, "Empresa");
                this.cargando = false;
            }).catch(e=>{
                //Futuro cambio en el mostrar error
                this.mostrarError(e, true);
            });
        },
        methods: {
            
            /*
            * Obtiene el error que sucedio
            */
            mostrarError(e){
                console.log(e.response ? e.response : e);
                if(e.response?.data){
                    this.error = e.response.data;
                    this.error = e.response.data.detail;
                }
                else
                    this.error = e;
                console.log(this.error)
                this.cargando = false;
            }
        }
    }
</script>