import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import { Tooltip } from 'bootstrap/dist/js/bootstrap.min.js'

import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/js/all.min.js'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
 //import { jwtInterceptor } from './api/axios'


new Tooltip(document.body, {
    selector: "[data-bs-toggle='tooltip']",
});

var app = createApp(App).use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 15,
  newestOnTop: true
});

app.config.errorHandler = (err, instance, info) => {
  // report error to tracking services
  console.log("Error", err);
  console.log("Instancia", instance);
  console.log("Informacion", info);
}
//app.component('EasyDataTable', Vue3EasyDataTable);
//jwtInterceptor();


app.use(router).mount('#app');