<template>
    <div>
        <ModalVue @ok="guardar()" @cancel="$emit('cerrar')" >
            <template v-slot:header>
                <div v-if="!empresaPersonal">
                    <h3 v-if="edicion">Editar</h3>
                    <h3 v-if="!edicion">Agregar</h3>
                </div>
                <div v-else>
                    <h3 v-if="empresa">{{empresa.nombre}}</h3>
                </div>
            </template>
            <template v-slot:body>
                <div v-if="cargando" class="centarItems" style="height: 350px;overflow-y: auto;">
                    <i class="fas fa-spinner fa-spin"></i> Cargando ...
                </div>

                <div v-else style="max-height: 350px;overflow-y: auto;">
                    <div style="display: inline-block; margin-right: 15px;">
                        <div class="d-flex flex-row-reverse" v-if="edicion">
                            <div class="p-2" v-if="!(empresa.id_organizacion == null || empresa.id_organizacion == '')">
                                <button type="button" class="btn btn-primary btn-sm" @click="modalClaveTecnica = true" >
                                    <i class="fa-solid fa-key"></i> &nbsp; Clave técnica
                                </button>
                            </div>
                            <div class="p-2" v-if="!(empresa.id_organizacion == null || empresa.id_organizacion == '')">
                                <button type="button" class="btn btn-primary btn-sm" @click="setPrueba.modal = true; setPrueba.enviarSet = true;" >
                                    <i class="fa-solid fa-paper-plane"></i>&nbsp; Enviar set pruebas
                                </button>
                            </div>
                            <div class="p-2" v-if="!(empresa.id_set == null || empresa.id_set == '')">
                                <button type="button" class="btn btn-primary btn-sm" @click="setPrueba.modal = true; setPrueba.enviarSet = false;" >
                                    <i class="fa-solid fa-circle-info"></i>&nbsp; Estado set pruebas
                                </button>
                            </div>
                            <div class="p-2"  v-if="(empresa.id_organizacion == null || empresa.id_organizacion == '')" >
                                <button type="button" class="btn btn-primary btn-sm" @click="generarIdOrganizacion(empresa)">
                                    <i class="fa-solid fa-paper-plane"></i>&nbsp; Enviar organización a alegra
                                </button>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                <label class="col-sm-4 ">Empresa</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="empresa.nombre" 
                                        :class="!empresa.nombre && error ? 'is-invalid' : ' '"/>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                    <label class="col-sm-4 ">Nombre comercial</label>
                                    <div class="col-sm-8">
                                        <input class="form-control" v-model="empresa.nombre_comercial" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" >
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                    <label class="col-sm-4 ">Tipo de identificación</label>
                                    <div class="col-sm-8">
                                        <select class="form-select" v-model="empresa.tipo_identificacion"
                                            :class="!empresa.tipo_identificacion && error ? 'is-invalid' : ' '">
                                            <option :value="null">Seleccionar</option>
                                            <option v-for="tipo in tipo_identificaciones" :value="tipo.code" :key="tipo.code">{{tipo.value}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">NIT</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="empresa.documento" :disabled="edicion" 
                                        :class="!empresa.documento && error ? 'is-invalid' : ' '"/>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" >
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                    <label class="col-sm-4 col-form-label">Régimen</label>
                                    <div class="col-sm-8">
                                        <select class="form-select" v-model="empresa.regimen_codigo"
                                            :class="!empresa.regimen_codigo && error ? 'is-invalid' : ' '">
                                            <option :value="null">Seleccionar</option>
                                            <option v-for="r in regimenes" :key="r.code" :value="r.code">{{r.code}} - {{r.value}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                    <label class="col-sm-4 col-form-label">Detalles Tributarios</label>
                                    <div class="col-sm-8">
                                        <select class="form-select" v-model="empresa.tax" @change="onCambioTaxcode()">                                            
                                            <option v-for="r in taxCodes" :key="r.id" :value="r.id">{{r.id}} {{r.name ? '-': '' }} {{r.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                    <label class="col-sm-4 col-form-label">DV</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" v-model="empresa.dv" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" >
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">Set de pruebas</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="empresa.id_set"/>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                    <label class="col-sm-4 col-form-label">Id organización</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" v-model="empresa.id_organizacion" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" >
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">Email</label>
                                <div class="col-sm-8">
                                    <input type="email" class="form-control" v-model="empresa.email" />
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">Clave</label>
                                <div class="col-sm-8">
                                    <div class="input-group" v-if="clave">
                                        <input ID="Password" type="Password" Class="form-control" v-model="empresa.clave">
                                        <div class="input-group-append">
                                            <button id="show_password" class="btn btn-primary" type="button" @click="clave = false"> <i class="fa-solid fa-eye"></i> </button>
                                        </div>
                                    </div>
                                    <div class="input-group" v-else>
                                        <input ID="txtPassword" type="text" Class="form-control" v-model="empresa.clave">
                                        <div class="input-group-append">
                                            <button id="show_password" class="btn btn-primary" type="button" @click="clave = true"> <i class="fa-solid fa-eye-slash"></i> </button>
                                        </div>
                                    </div>
                                    <!-- input type="text" class="form-control" v-model="empresa.clave" / -->
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                    <label class="col-sm-4 ">Teléfono</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" v-model="empresa.telefono" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                    <label class="col-sm-4 col-form-label">País</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" v-model="empresa.pais" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                    <label class="col-sm-4 col-form-label">Departamento</label>
                                    <div class="col-sm-8">
                                        <select class="form-select" v-model="empresa.departamento" @change="getCiudades()">
                                            <option :value="null">Seleccionar</option>
                                            <option v-for="d in departamentos" :key="d.code" :value="d.code">{{d.value}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                    <label class="col-sm-4 col-form-label">Ciudad</label>
                                    <div class="col-sm-8">
                                        <select class="form-select" v-model="empresa.ciudad">
                                            <option :value="null">Seleccionar</option>
                                            <option v-for="c in ciudadesSeleccionadas" :key="c.code" :value="c.code">{{c.value}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" >
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                <label class="col-sm-4 col-form-label">Dirección</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="empresa.direccion" />
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="test" v-model="empresa.test" :disabled="edicion">
                                            <label class="form-check-label" for="test">
                                                Test
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" v-model="empresa.activo" id="activo">
                                            <label class="form-check-label" for="activo">
                                                Activo
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" v-model="empresa.enviar_email" id="enviarEmail">
                                    <label class="form-check-label" for="enviarEmail">
                                        Enviar email
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-2 row">
                                    <label class="col-sm-4 col-form-label">Documentos email</label>
                                    <div class="col-sm-8">
                                        <select class="form-select" v-model="empresa.documentos_email">
                                            <option :value="null">Todos</option>
                                            <option v-for="tipo in tiposDocumentos" :key="tipo" :value="tipo">{{tipo}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="mb-2 row">
                                    <label class="col-sm-2">Email template</label>
                                    <div class="col-sm-10">
                                        <textarea class="form-control" v-model="empresa.email_template"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="error != null" class="centarItems" >
                            <div class="alert alert-danger">
                                {{error}}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </ModalVue>

        <ClavesTecnicas v-if="modalClaveTecnica" @cerrar="modalClaveTecnica = false" :idEmpresa="idEmpresa"></ClavesTecnicas>
        <SetPruebas v-if="setPrueba.modal" @cerrar="setPrueba.modal = false; setPrueba.setId = null" @ok="actualizarSet" :idEmpresa="empresa.id_empresa" :enviarSet="setPrueba.enviarSet"></SetPruebas>
    </div>
</template>
<script>
    import EmpresaService from '@/services/EmpresaService';

    import ModalVue from '@/components/Modales/Modal.vue'
    import ClavesTecnicas from '@/components/Modales/ClavesTecnicas.vue'
    import SetPruebas from '@/components/Modales/SetPrueba.vue'

    import _ from 'underscore';
    import { useToast } from 'vue-toastification'
import AlegraService from '@/services/AlegraService';
    
    export default {
        name: "ModalEmpresa",
        components: {
            ModalVue,
            ClavesTecnicas,
            SetPruebas
        },
        props:{
            idEmpresa: Number,
            empresaPersonal: Boolean
        },
        emits: ["cerrar", "ok"],
        data(){
            return {
                toast: useToast(),
                cargando: true,
                error: null,
                //Contiene la empresa seleccionada
                empresa: {},
                //Indica si se edita la empresa
                edicion: true,
                //Contiene el nombre y valor de los tipos de indentificacion
                tipo_identificaciones: [],
                //Contiene los regimenes
                regimenes: [],
                //Contiene los departamentos
                departamentos: [],
                //Contiene las ciudades
                ciudades: [],
                //Contiene las ciudades del pepartamento seleccionado
                ciudadesSeleccionadas: [],
                seleccionDocumento: true,
                //Contiene los documentos que se envian automaticamente los emails
                tiposDocumentos: ["Factura"],

                modalClaveTecnica: false,
                setPrueba: {modal: false, enviarSet: false, setId: null},
                clave: true,
                taxCodes: []
            }
        },
        async mounted(){
            var userEmpresa = EmpresaService.getEmpresaUser();
            if(!userEmpresa.superadmin){
                setTimeout(function(){
                    window.location = "#/";
                }, 50);
            }
            
            this.tipo_identificaciones = [
                {"code":"13","value": "Cédula de ciudadanía"},
                {"code":"31","value":"NIT"},
                {"code":"21","value":"Tarjeta de extranjería"},
                {"code":"22","value":"Cédula de extranjería"},
                {"code":"41","value":"Pasaporte"},
                {"code":"42","value":"Documento de identificación extranjero"},
                {"code":"47","value":"PEP"},
                {"code":"50","value":"NIT de otro país"}];

            AlegraService.getDatosTipos("fiscal-Responsability-types").then((t)=>{
                var data = t.data;
                this.regimenes = data;
                sessionStorage.setItem("fiscal-Responsability-types", JSON.stringify(this.regimenes))

                //this.regimenes.unshift({code:"O-48", value:"Impuesto sobre las rentas"});
                //this.regimenes.unshift({code:"O-49", value:"No responsable de IVA"})
            });

            // AlegraService.getDatosTipos("tax-types").then((t)=>{
            //     var data = t.data;
            //     this.taxCodes = data.map(t=>{ return {id: t.code, name: t.value}});

            //     this.taxCodes.unshift({id: "", name: ""});
                
            // });

            var t = await AlegraService.getDatosTipos("tax-types");
            var data = t.data;
            this.taxCodes = data.map(t=>{ return {id: t.code, name: t.value}});

            this.taxCodes.unshift({id: "", name: ""});
            
            AlegraService.getDatosTipos("municipalities").then(r=>{
                var datos = r.data;
                sessionStorage.setItem("municipalities", JSON.stringify(datos));
                this.ciudades = datos;

                AlegraService.getDatosTipos("departments").then(d=>{
                    this.departamentos = d.data;
                    sessionStorage.setItem("departments", JSON.stringify(this.departamentos));

                    if(this.idEmpresa == 0){
                        this.empresa = { 
                            id_empresa: 0,
                            clave: null,
                            documento: null,
                            dv: null,
                            regimen_codigo: null,
                            email: null,
                            nombre: null,
                            telefono: null,
                            tipo_identificacion: null,
                            direccion: null,
                            activo: true,
                            departamento: null,
                            ciudad: null,
                            pais: "CO",
                            tax_code: ""
                        };
                        this.edicion = false;
                        this.cargando = false;
                    }else{
                        this.edicion = true;
                        //Obtiene las empresa
                        EmpresaService.getEmpresa(this.idEmpresa).then(r=>{

                            var dato = r.data.Empresa;
                            this.empresa = dato;
                            
                            if(this.empresa.departamento)
                                this.ciudadesSeleccionadas = _.filter(this.ciudades, function(c){ return c.departmentCode == dato.departamento});

                            this.empresa.tax = null;
                            if(this.empresa.tax_code)
                                this.empresa.tax = JSON.parse(this.empresa.tax_code).id;

                            this.cargando = false;
                        }).catch(e=>{
                            this.mostrarError(e);
                        })
                    }
                })
            });
        },
        methods: {
            //Actualiza el objeto taxcode
            onCambioTaxcode(){
                if(this.empresa.tax)
                    this.empresa.tax_code = JSON.stringify(this.taxCodes.find(i => i.id == this.empresa.tax));
                else
                    this.empresa.tax_code = null;
                console.log(this.empresa.tax, this.empresa.tax_code);
            },
            /**
             * Obtiene las ciudades de acuerdo con el departamento seleccionado
             */
            getCiudades(){
                var empresa = this.empresa;
                //Deja la ciudad sin una seleccion
                empresa.ciudad = null;
                //Obtiene las ciudades del departamento seleccionado
                this.ciudadesSeleccionadas = _.filter(this.ciudades, function(c){ return c.departmentCode == empresa.departamento});

            },

            /*
            *  Guarda los cambios de la empresa editada o la nueva empresa
            */
            guardar(){
                this.cargando = true;
                //Guarda los datos
                EmpresaService.guardarEmpresa(this.empresa).then(r=> {
                    console.log(r.data);

                    this.toast.success("Se han guardado los cambios", {
                        position: "top-right"
                    });
                    this.cargando = false;
                    this.$emit("ok");
                }).catch(e=> {
                    this.mostrarError(e);
                });
            },

            /*
            * Actualiza el id_set en la empresa seleccionada
            */
            actualizarSet(dato){
                var empresa = this.empresa;
                empresa["id_set"] = dato;

                var index = _.findIndex(this.empresas, function(e){ return e.id_empresa == empresa.id_empresa});
                if(index >= 0)
                    this.empresas[index] = empresa;

                this.setPrueba.modal = false;
            },

            /*
            * Envia a alegra la organizacion
            */
            generarIdOrganizacion(empresa){
                this.cargandoModal = true;
                //Genera el id de la organización
                EmpresaService.generarIdOrganizacion(empresa).then(r=>{
                    var empresa = r.data;
                    //Obtiene la empresa actualizada
                    this.getEmpresa(empresa.id_empresa);
                    this.toast.success("El ID se generó exitosamente", {
                        position: "top-right"
                    });

                    this.cargando= false;
                    this.modal = false;
                }).catch(e=> {
                    this.mostrarError(e, true);
                })
            },
     
            /*
            * Obtiene el error que sucedio
            */
            mostrarError(e){
                var error = e.response ? e.response : e;
                console.log(error);
                
                if(e.data)
                    error = e.data;

                this.cargando = false;
                console.log(error);
                //Ingresa el error encontrado
                this.error = error;

                this.toast.error(error.toString());
                
                
            },

        }
    }
</script>
