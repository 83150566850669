import axios from "@/api/axios";

class AlegraService{
    constructor(){
    }

    getTiposIdentificacion(){
        return this.getDatosTipos("identification-types");
    }

    getTiposOrganizacion(){
        return this.getDatosTipos("organization-types");
    }

    getMetodoPagos(){
        return this.getDatosTipos("payment-methods");
    }

    getFormaPago(){
        return this.getDatosTipos("payment-forms");
    }

    getDepartamento(){
        return this.getDatosTipos("departments");
    }

    getCiudades(){
        return this.getDatosTipos("municipalities");
    }

    getUnidadesMedida(){
        return this.getDatosTipos("unit-codes");
    }

    getTipoImpuesto(){
        return this.getDatosTipos("tax-types");
    }

    getDatosTipos(tipo){
        var datos = this.getDatosSeccion(tipo);
        var request = null;
        
        if(datos){
            request = new Promise((resolve)=>{ 
                resolve({data: datos});
            })
        }else{
            request = axios.get("api/Dian/datos?tipo=" + tipo)
        }

        return request; 
    }

    getDatosSeccion(dato){
        var datos = sessionStorage.getItem(dato)
        
        if(datos)
            datos = JSON.parse(datos)

        return datos;
    }
}

export default new AlegraService();