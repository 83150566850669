<template>
    <ModalVue  @ok="actualizarPassword()"  @cancel="$emit('cerrar')">
        <template v-slot:header>
            <h3>Cambiar contraseña</h3>
        </template>
        <template v-slot:body>
            <div v-on:keyup.enter="actualizarPassword()">
                <div class="mb-3 row">
                    <label for="passwordOld" class="col-sm-4 col-form-label">Contraseña actual</label>
                    <div class="col-sm-8">
                        <input type="password" class="form-control" id="passwordOld" v-model="paswords.actual" required>
                    </div>
                    </div>
                    <div class="mb-3 row">
                    <label for="inputPassword" class="col-sm-4 col-form-label">Nueva contraseña</label>
                    <div class="col-sm-8">
                        <input type="password" class="form-control" id="inputPassword" v-model="paswords.nueva1" required>
                    </div>
                    </div>
                    <div class="mb-3 row">
                    <label for="inputPassword2" class="col-sm-4 col-form-label">Confirmar contraseña</label>
                    <div class="col-sm-8">
                        <input type="password" class="form-control" id="inputPassword2" v-model="paswords.nueva2" required>
                    </div>
                    <br>
                    <div v-if="error">
                        <div class="alert alert-danger">
                            {{error}}
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </ModalVue>
</template>

<script>
    import EmpresaService from "@/services/EmpresaService";
    import ModalVue from '@/components/Modales/Modal.vue'

    import { useToast } from 'vue-toastification'

    export default {
        name: 'ActualizarPasword',
        components:{
            ModalVue
        },
        data() {
            return {
                toast: useToast(),
                //Contiene la empresa del usuario
                empresa: null,
                //Contiene las contraseñas
                paswords: {actual: null, nueva1: null, nueva2: null},
                error: null
            }
        },
        emits: ["cerrar"],
        mounted(){
            //Obtiene la empresa del usuario
            this.empresa = EmpresaService.getEmpresaUser();
        },
        methods: {
            /*
            * Actualiza la contraseña del usuario
            */
            actualizarPassword(){
                this.error = null;
                //Valida las contraseñas
                if(!this.paswords.actual || !this.paswords.nueva1 || !this.paswords.nueva2)
                    this.error = "Las contraseñas son requeridas"

                if(this.paswords.nueva1 != this.paswords.nueva2)
                    this.error = "Las contraseñas no coinciden"
                
                if(this.error != null)
                    return;
                //autentifica y actualiza la contraseña
                EmpresaService.actualizarPasword(this.empresa.id_empresa, this.paswords.actual, this.paswords.nueva1).then(r=>{
                    console.log(r.data);
                    console.log("Actualizando contraseñas");
                    this.toast.success("Se ha actualizado correctamente", {
                        position: "top-right"
                    });
                    this.$emit('cerrar');
                }).catch(e=>{
                    this.mostrarError(e);
                });
            },
            
            /*
            * Obtiene el error que sucedio
            */
            mostrarError(e){
                console.log(e.response ? e.response : e);
                if(e.response?.data){
                    this.error = e.response.data;
                    this.error = e.response.data.detail.error;
                }
                else
                    this.error = e;

                this.toast.error(this.error.toString(), {
                        position: "top-right"
                    });
            }
        }
    }
</script>