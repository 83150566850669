<template>
    <ModalVue @cancel="$emit('cerrar')" @ok="enviarSetPruebas()" :modoConfimacion="!enviarSet">
        <template v-slot:header>
            <h3 v-if="enviarSet">Enviar set de pruebas</h3>
            <h3 v-else>Estado del set de pruebas</h3>
        </template>
        <template v-slot:body>
            <div v-if="cargando" class="centarItems">
                <i class="fas fa-spinner fa-spin"></i> Cargando ...
            </div>
            <div class="mb-3 mt-3" v-if="enviarSet">
                <label for="unidGovernment" class="form-label">Id government</label>
                <input type="text" class="form-control" id="unidGovernment" name="unidGovernment" required v-model="idGovernment">
                <br>
                <div v-if="id_gobernment" class="alert alert-danger">No ha ingresado ningún id government</div>
                
            </div>
            <div v-else >
                <br>
                <div v-if="info != null">
                    <textarea disabled style="width: 100%; resize: vertical;" v-model="info">
                    </textarea>
                </div>
            </div>
            <div v-if="!cargando && error != null" class="centarItems">
                <div class="alert alert-danger">
                    {{error}}
                </div>
            </div>
        </template>
    </ModalVue>
</template>
<script>
import EmpresaService from "@/services/EmpresaService";
import ModalVue from '@/components/Modales/Modal.vue'

import { useToast } from 'vue-toastification'
//import _ from 'underscore';

    export default {
        name: 'SetPruebas',
        props:{
            idEmpresa: Number,
            enviarSet: Boolean,
        },
        components:{
            ModalVue
        },
        data() {
            return {
                toast: useToast(),
                //Contiene el id 
                idGovernment: null,
                cargando: false,
                error: null,
                info: null,
                id_gobernment: false
            }
        },
        emits: ["cerrar", "ok"],
        mounted(){
            if(!this.enviarSet)
                this.getEstadoPrueba()
        },
        methods: {
            enviarSetPruebas(){
                this.error = null;
                this.cargando = true;
                this.id_gobernment = false;

                if(!this.idGovernment){
                    this.id_gobernment = true;
                    this.cargando = false;
                    return;
                }
                    
                EmpresaService.enviarPrueba(this.idEmpresa, this.idGovernment).then(r=>{
                    var dato = r.data;
                    console.log(dato);
                    this.info = JSON.stringify(dato);
                    
                    this.cargando = false;
                    this.$emit("ok", dato["id"]);
                    
                    this.toast.success( " Se ha enviado el set de pruebas", {
                        position: "top-right"
                    })
                }).catch(e=>{
                    this.mostrarError(e);
                })
            },
            getEstadoPrueba(){
                this.error = null;
                this.cargando = true;

                EmpresaService.getEstadoPrueba(this.idEmpresa).then(r=>{
                    var data = r.data;

                    console.log(data);
                    this.info = JSON.stringify(data);
                    this.cargando = false;
                }).catch(e=>{
                    this.mostrarError(e);
                })
            },

            /*
            * Obtiene el error que sucedio
            */
            mostrarError(e){
                console.log(e.response ? e.response : e);
                if(e.response?.data){
                    this.error = e.response.data;
                    this.error = e.response?.data.detail;
                    console.log(typeof(this.error))
                }
                else
                    this.error = e;
                console.log(this.error)

                this.toast.error(this.error.toString(), {
                    position: "top-right"
                });
                this.cargando = false;
            }
        }
    }
</script>