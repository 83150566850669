import axios from "axios"; 

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/' :  "http://localhost:8000/",
  /*headers: {
    "Content-Type": "application/json",
  },*/
});

instance.x = "x";

 //function jwtInterceptor() {

instance.interceptors.request.use((data)=>{
    let token = sessionStorage.getItem('Token');

    if(token)
        instance.defaults.headers.common['Authorization'] = 'bearer ' + token;

    return data;
});

instance.interceptors.response.use((data) => {

    let token = sessionStorage.getItem('Token');
    var headers = data.headers;
    
    var tokenNew = headers.token;

    //Obtiene el nuevo token
    if(tokenNew && tokenNew != token){
        sessionStorage.setItem("Token", tokenNew);
        instance.defaults.headers.common['Authorization'] = 'bearer ' + tokenNew;
    }

    return data;
},(e)=> {
    if(!e?.response)
        return Promise.reject(e);
    //Obtiene el error
    var error = e.response.data?.detail;

    if(error == "No pudo validar las credenciales" || error == "Empresa no se encuentra activa"){
        //Guarda el mensaje de error
        sessionStorage.setItem("mensaje", "Se ha cerrado la sesión por inactividad")
        //Elimna los datos guardados en la session
        sessionStorage.removeItem("empresa");
        sessionStorage.removeItem("Token");
        //Redirige y recarga la pagina inicial
        window.location.href = "#/";
        location.reload();
    }
    // Do something with request error
    return Promise.reject(e);
});

//console.log("axios", axios.interceptors.response);
//}

export default instance;