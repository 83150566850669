<!-- template for the modal component -->
<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container">

              <div class="modal-header">
                  <slot name="header">
                    &nbsp;
                  </slot>
                  <div class="position-relative">
                      <div class="position-absolute top-0 start-0"></div>
                  </div>
                  <button type="button" @click="$emit('cancel')" class="btn close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>

              <div class="modal-body">
                  <slot name="body">
                  default body
                  </slot>
              </div>

              <div class="modal-footer">
                  <slot name="footer">
                    <button class="modal-default-button btn btn-primary" @click="$emit('cancel')" v-if="modoConfimacion">
                      <span >Cerrar</span>
                    </button>
                    <button class="modal-default-button btn btn-primary" @click="$emit('ok')" v-if="!modoConfimacion">
                      <span>Aceptar</span>
                    </button>
                    <button class="modal-default-button btn btn-secundary" v-if="!modoConfimacion" @click="$emit('cancel')">
                        Cancelar
                    </button>
                  </slot>
              </div>
            </div>
        </div>
        </div>
    </transition>
</template>

<script>
export default ({
  name: "ModalVue",
  props: {    
    modoConfimacion: Boolean,
  },
})
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 840px;
  max-height: 100%;
  margin: 0px auto;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header{
    padding: 0.5rem 0.5rem;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 0px 0;
}
.modal-footer{
  padding: 0;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>