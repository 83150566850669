import axios from "@/api/axios";
class EmpresaService {
	
    constructor(){
    }

    autenticar(nit, pasword) {
        //Valida las credenciales
        return axios.post("api/login",  new URLSearchParams({
            username: nit, 
            password: pasword
        }))
    }
    
    /**
     * Busca la sesión en el almacenamiento local y la reestablece
     * @returns 
     */
	getLogin(){
        var guid = sessionStorage.getItem('Token');
        //console.log(guid);
        if(guid)
            return true;
        else
            return false;
    }

    salir(){
        sessionStorage.clear();
        return false;
    }

    getEmpresaUser(){
        var empresa = sessionStorage.getItem("empresa");

        return JSON.parse(empresa);
    }

    getEmpresa(idEmpresa){
        return axios.get("api/empresa?idEmpresa=" + idEmpresa);
    }

    getEmpresas(){
        return axios.get("api/empresas");
    }

    guardarEmpresa(empresa){
        return axios.post("api/empresa/guardar", {empresa: empresa});
    }

    eliminarEmpresa(idEmpresa){
        return axios.delete("api/empresa/eliminar?idEmpresa=" + idEmpresa);
    }

    actualizarPasword(idEmpresa, claveOld, calveNew){
        return axios.post("api/empresa/cambiarClave", {idEmpresa: idEmpresa, paswordOld: claveOld, paswordNew: calveNew});
    }

    getClaveTecnica(idEmpresa){
        return axios.get("api/empresas/clavesTecnicas?idEmpresa=" + idEmpresa);
    }

    generarIdOrganizacion(empresa){
        return axios.post("api/empresas/generarIdOrganizacion", {empresa: empresa})
    }

    enviarPrueba(idEmpresa, idGovernment){
        return axios.post("api/empresas/enviarPruebas", {idEmpresa: idEmpresa, governmentId: idGovernment});
    }

    getEstadoPrueba(idEmpresa, idSet){
        return axios.get("api/empresas/estadoPruebas?idEmpresa=" + idEmpresa + "&setid=" + idSet);
    }
}

export default new EmpresaService();