import axios from "@/api/axios";
import { useToast } from 'vue-toastification';

class FacturacionService {
	
    constructor(){      
        this.toast = useToast();
    }
	
    ping() {
        return axios.get("api/ping");
    }

    resumen(desde, hasta, idEmpresa, detalle){
        return axios.get("api/facturas/emitidas?desde=" + desde + "&hasta=" + hasta + (idEmpresa?  "&idEmpresa=" + idEmpresa + "&detalle=" + detalle : "" ));
    }

    getFacturas(desde, hasta, idEmpresa, estadoFactura, pg, numeroFac, tipoFac, cliente, sizePg){
        var url = "api/facturas?desde=" + desde + "&hasta=" + hasta + "&idEmpresa=" + idEmpresa + "&estadoFactura=" + estadoFactura ;
        url += pg ?  "&pg=" + pg : "";
        url += numeroFac ?  "&numeroFac=" + numeroFac : "";
        url += tipoFac ? "&tipoFactura=" + tipoFac : "";
        url += cliente ? "&cliente=" + cliente : "";
        url += sizePg ? "&sizePg=" + sizePg : "";

        return axios.get(url );
    }

    getFactura(idFactura){
        return axios.get("api/factura?idFactura=" + idFactura)
    }

    getFacturasCantidad(desde, hasta, idEmpresa){
        return axios.get("api/facturas/resumen?desde=" + desde + "&hasta=" + hasta + "&idEmpresa=" + idEmpresa  )
    }

    getValidacionConsecutivos(idEmpresa, desde, hasta){
        return axios.get("api/facturas/consecutivos?idEmpresa=" + idEmpresa + "&desde=" + desde + "&hasta=" + hasta)
    }

    descargarFacturas(idEmpresa, estadoFactura, desde, hasta, numeroFac, tipoFac, cliente){
        var url = "api/facturas/descargarFacturas?desde=" + desde + "&hasta=" + hasta + "&idEmpresa=" + idEmpresa + "&estadoFactura=" + estadoFactura ;
        url += numeroFac ?  "&numeroFac=" + numeroFac : "";
        url += tipoFac ? "&tipoFactura=" + tipoFac : "";
        url += cliente ? "&cliente=" + cliente : "";

        // "api/facturas/descargarFacturas?idEmpresa=" + idEmpresa + "&estadoFactura=" + estadoFactura +"&desde=" + desde + "&hasta=" + hasta,
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            if(estadoFactura == "factura")
                estadoFactura = "Aprobadas"
            
            link.setAttribute('download', "facturas_"+ estadoFactura +".xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(e=>{
            console.log(e);
            this.toast.error("Error al descargar", {
                position: "top-right"
            });
        });
        //window.open("api/facturas/descargarFacturas?idEmpresa=" + idEmpresa + "&estadoFactura=" + estadoFactura +"&desde=" + desde + "&hasta=" + hasta);
    }

    descargarPDFDian(cufe){

        axios({
            url: "https://catalogo-vpfe-hab.dian.gov.co/Document/DownloadPDF?trackId=" + cufe,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            
            link.setAttribute('download', "facturas_"+ cufe +".xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(e=>{
            console.log(e);
            this.toast.error("Error al descargar", {
                position: "top-right"
            });
        });
    }

    enviarEmail(idFactura, copiaEmail){
        var url = "api/facturas/enviarEmail?idFactura=" + idFactura;

        if(copiaEmail)
            url += "&copiaEmail=" + copiaEmail

        return axios.get(url);
    }
}

export default new FacturacionService();